import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>

      <hr></hr>
      <p>
        <center><strong>
          "the significant problems we face cannot be solved by the same level of thinking that created them" Albert Einstein
        </strong></center>
      </p>
      <hr></hr>
      <p>
        <center>
          To understand the problems with IT Architecture,  we need to focus on the right level of analysis. If principles, standards, policies, capabilities, planning, and technology choices are not laid out at the enterprise wide level, solutions at the program level will be affected. To sum it up, without an architectural plan, individual components may be engineered with excellence but will fail to integrate with the other parts.
        </center>
      </p>
      <p>
        <center>
          <strong> Gus Khosrowkhani</strong> with over 30 years of IBM and marketplace experience has the depth, breadth, and the experience to assist companies in Enterprise Architecture,  Solutions Architeture, Integration/SOA/EAI Architectures, as well as IT Services Management.
        </center>
      </p>
      <hr></hr>
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
